body {
  background-color: transparent;
}

.NoBackground {
  --mb-color-embed-bg-color-override: transparent;
}

.EmbedFrame {
  --mb-color-text-primary: var(--mb-color-text-dark);
  --mb-color-text-secondary: var(--mb-color-text-medium);
  --mb-color-text-tertiary: var(--mb-color-text-light);
  --mb-color-embed-filter-bg-color: var(--mb-color-bg-white);

  color: var(--mb-color-text-primary);
  background-color: var(
    --mb-color-embed-bg-color-override,
    var(--mb-color-bg-dashboard)
  );
}
/* A temporary solution until we migrated all colors in visualization to use semantic colors
   e.g. `text-primary`, `text-secondary`, `text-tertiary`. */
.EmbedFrame svg text {
  fill: var(--mb-color-text-primary);
}

.EmbedFrameHeader,
.EmbedFrameFooter {
  color: var(--mb-color-text-primary);
  background-color: var(
    --mb-color-embed-bg-color-override,
    var(--mb-color-bg-dashboard)
  );
}

.ThemeNight.EmbedFrame,
/* this is to make it work when exporting to pdfs,
where the EmbedFrame is not part of the exported dom */
.ThemeNight.EmbedFrame .WithThemeBackground {
  --mb-color-text-primary: color-mix(
    in srgb,
    var(--mb-color-text-white) 90%,
    transparent
  );
  --mb-color-text-secondary: color-mix(
    in srgb,
    var(--mb-color-text-white) 65%,
    transparent
  );
  --mb-color-text-tertiary: color-mix(
    in srgb,
    var(--mb-color-text-white) 45%,
    transparent
  );
  --mb-color-embed-filter-bg-color: var(--mb-color-bg-black);
  --mb-color-border: var(--mb-color-text-medium);

  background-color: var(
    --mb-color-embed-bg-color-override,
    var(--mb-color-bg-black)
  );
  border-color: var(--mb-color-bg-dark);
}

.ThemeNight .EmbedFrameHeader,
.ThemeNight .EmbedFrameFooter {
  background-color: var(
    --mb-color-embed-bg-color-override,
    var(--mb-color-bg-black)
  );
  border-color: var(--mb-color-bg-dark);
}

.ThemeNight.EmbedFrame .fullscreenNightText {
  transition: color 1s linear;
}

.ThemeNight.EmbedFrame svg text {
  stroke: none !important;
}

.ThemeNight.EmbedFrame .DashCard .Card {
  background-color: var(--mb-color-bg-black);
  border: 1px solid var(--mb-color-border);
}

.ThemeTransparent.EmbedFrame {
  background-color: transparent;
}

.ThemeTransparent .EmbedFrameHeader,
.ThemeTransparent .EmbedFrameFooter {
  background-color: transparent;
}

.ThemeTransparent.EmbedFrame .DashCard .Card {
  background-color: transparent;
}
